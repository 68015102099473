import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import MediaQuery from 'react-responsive';
import { toggleMenuModal } from '../../../redux/actions/menuAction';
import { toggleLoginModal, toggleSignupModal } from '../../../redux/actions/loginSignupAction';
import './HomepageMenuModal.css';
import localStorageApi from '../../../api/localStorageApi.js';
import { 
    setActivityTrackingData, setTryPowerSearch 
} from '../../../redux/actions/searchAction';
import {
    EP_ACCOUNT,
    ET_ACCOUNT_ONLOAD, HEADER_ACCOUNT_BUTTON, LOGIN_EVENT_PAGE, LOGIN_TOGGLE_ON_EVENT_TYPE,
    TOGGLE_SELF_CLICK,
    HOMEPAGE_MENU_MODAL, HEADER_WHAT_WE_DO_BUTTON,
    EP_HOMEPAGE, 
    HEADER_SINGLE_SEARCH_SEARCHBAR_ENTER,
    ET_HEADER_SINGLE_SEARCH,
    HEADER_SINGLE_SEARCH_SEARCHBAR_SEARCH,
    HEADER_SINGLE_SEARCH_SEARCHBAR_OPTION,
    HEADER_SINGLE_SEARCH_SEARCHBAR_ICON
} from '../../../common/trackingConstants';
import { sendActivityTracking, setRequestDemoModalStatus } from '../../../redux/actions/commonAction';
import { TOGGLE_LOGIN_MODAL_ON_SUCCESS } from '../../../common/loginSignupSuccessConstants';
import { isBrowser } from '../../../common/config';
import { getOnloadEventType } from '../../common/PowerSearchFunctions';
import SingleSearchBar from '../../SingleSearchBar/SingleSearchBar.js';

export const menuDropDowns = [{
    name: "Solutions",
    options: [{
        text: "For Business",
        url: "/solutions/business",
        eventType: ""
    }, {
        text: "For Sales",
        url: "/solutions/sales",
        eventType: ""
    }, {
        text: "For Advisors",
        url: "/solutions/advisors",
        eventType: ""
    }, {
        text: "For Investors",
        url: "/solutions/investors",
        eventType: ""
    }, {
        text: "Data Enrichment",
        url: "/solutions/datacleansing",
        eventType: ""
    }, {
        text: "Pomanda API",
        url: "/pomanda-api-service",
        eventType: ""
    }],
    isDropDown: true,
    id: "solutions",
    showForNonSubscriber: true,
    eventType: "ET_HEADER_SOLUTIONS_DROPDOWN_CLICK"
}, {
    name: "Use Case",
    eventType: "ET_HEADER_USE_CASE_DROPDOWN_CLICK",
    options: [{
        text: "Accountants",
        desc: "Win clients, boost revenue, and add value",
        url: "/sector/accountants"
    }, {
        text: "Private Equity",
        desc: "Find opportunities and value with Pomanda’s industry leading insight",
        url: "/sector/privateequity"
    }, {
        text: "Sales & Marketing",
        desc: "Quickly and easily identify your target market to boost your sales",
        url: "/sector/salesandmarketing"
    }],
    isDropDown: true,
    id: "useCases",
    showForNonSubscriber: true,
}, {
    name: "Resources",
    eventType: "ET_HEADER_RESOURCES_DROPDOWN_CLICK",
    options: [{
        mainOption: 'Data',
        url: "/resources/data",
        subOptions: [{
            text: "Company Data",
            url: "/solutions/companyinformation"
        }, {
            text: "Financial Estimates",
            url: "/solutions/estimates"
        }, {
            text: "APIs",
            url: "/pomanda-api-service"
        }, {
            text: "Data Cleansing",
            url: "/solutions/datacleansing"
        }, {
            text: "Benchmarking",
            url: "/solutions/scorecard"
        }]
    }, {
        mainOption: 'Reports',
        url: "/resources/reports",
        subOptions: [{
            text: "Company Valuations",
            url: "/solutions/companyvaluation"
        }, {
            text: "Business Plans",
            url: "/solutions/businessplan"
        }, {
            text: "Credit Reports",
            url: "/solutions/companycreditcheck"
        }, {
            text: "Company Reports",
            url: "/resources/reports?id=companyreports"
        }]
    }, {
        mainOption: 'Lead Generation',
        url: "/resources/leadgeneration",
        subOptions: [{
            text: "Power Search",
            url: "/powersearch/company"
        }, {
            text: "Contacts",
            url: "/solutions/contacts"
        }, {
            text: "Keywords",
            url: "/resources/leadgeneration?id=keywords"
        }, {
            text: "Watchlists",
            url: "/resources/leadgeneration?id=watchlists"
        }, {
            text: "Exports",
            url: "/resources/leadgeneration?id=exports"
        }]
    }],
    isDropDown: true,
    id: "resources",
    showForNonSubscriber: true
}, {
    name: "Power Search",
    options: [],
    id: "powerSearch",
    isDropDown: false,
    url: "/powersearch/company"
}, {
    name: "Pricing",
    options: [],
    id: 'pricing',
    isDropDown: false,
    url: "/product"
}, {
    name: "Account",
    options: [],
    isDropDown: false,
    id: "account",
    authenticate: true,
    url: "/account/profile"
}]

class HomepageMenuModal extends Component {
    componentDidUpdate = (prevProps) => {
        if (prevProps.token !== this.props.token) {
            this.props.dispatchToggleMenuModal(false);
        }
    }

    getActivityTrackingForAccount = () => {
        this.props.dispatchSendActivityTracking({
            eventPage: EP_ACCOUNT,
            eventType: ET_ACCOUNT_ONLOAD,
            attribute1: HEADER_ACCOUNT_BUTTON,
        });
        this.props.dispatchToggleMenuModal(false)
    }

    getActivityTrackingForSolutions = () => {
        const {
            headerSingleSearchOptions = {}
        } = this.props;
        const {
            eventPage = EP_HOMEPAGE
        } = headerSingleSearchOptions;
        this.props.dispatchSendActivityTracking({
            eventPage,
            eventType: HEADER_WHAT_WE_DO_BUTTON,
            attribute1: HOMEPAGE_MENU_MODAL
        });
        this.props.dispatchToggleMenuModal(false)

    }

    toggleLoginModal = () => {
        this.props.dispatchToggleLoginModal({ status: true }, {
            eventPage: LOGIN_EVENT_PAGE, eventType: LOGIN_TOGGLE_ON_EVENT_TYPE,
            attribute1: TOGGLE_SELF_CLICK
        }, {
            toggleType: TOGGLE_LOGIN_MODAL_ON_SUCCESS
        })
        this.props.dispatchToggleMenuModal(!this.props.isMenuModalOpen)

    }

    closeModalAndInactiveDropDown = () => {
        this.props.dispatchToggleMenuModal(!this.props.isMenuModalOpen);
        this.setState({activeDropDown: ""})
    }

    handleTracking = (eventType) => {
        const {
            headerSingleSearchOptions = {}
        } = this.props;
        const {
            eventPage = EP_HOMEPAGE
        } = headerSingleSearchOptions;
        this.props.dispatchSendActivityTracking({
            eventPage,
            eventType,
            attribute1: HOMEPAGE_MENU_MODAL
        });
    }

    handleSetActiveDropDown = (isDropDown, dropDownId, eventType) => {
        if (isDropDown) {
            this.setState({ activeDropDown: this.state?.activeDropDown !== dropDownId ? dropDownId : "" });
        } else {
            this.props.dispatchToggleMenuModal(!this.props.isMenuModalOpen);
        };
        this.handleTracking(eventType);
    }

    handleDropDownDisplay = (dropDown, showAccMenu) => {
        const {
            hideHeaderDropDownFeatures = false
        } = this.props?.headerProps;
        const { showForNonSubscriber = false, authenticate = false, isDropDown = false} = dropDown;
        if (isDropDown && hideHeaderDropDownFeatures) return false;
        let showContent = null;
        if (showForNonSubscriber) {
            showContent = this.props?.userInfo?.subscription.active !== "yes";
        } else if (authenticate) {
            showContent = showAccMenu
        } else {
            showContent = true
        }
        return showContent;
    }

    getCustomUrl = (id, url) => {
        switch (id) {
            case "pricing":
                const pathname = isBrowser && window.location.pathname;
                const href = `${window.location.origin}/product`;
                return `/product?serviceReq=DEFAULT&url=${pathname}&href=${href}`;
            case "powerSearch":
                return `/powersearch/${this.props?.powerSearchType || "company"}`;
            default:
                return url;
        }
    }

    toggleDemoModal = () => this.props.dispatchSetRequestDemoModalStatus(true)

    render() {
        let userTrackerObj = localStorageApi.getUserData()
        let showAccMenu = false;
        if (userTrackerObj) {
            if (userTrackerObj.accessToken && userTrackerObj.accessToken !== "") {
                showAccMenu = true;
            }
        }
        let {
            powerSearchType = "company",
            headerProps = {},
            whiteLabelConfig = {},
            headerSearchText = "",
            headerSingleSearchOptions= {}
        } = this.props;
        const {
            desktopLogo = "",
            isPricingMenuShow = true,
            desktopWebpLogo = "",
            showMenuToggleTabMob = true,
            showPowerSearchNav= true,
            showSolutionsNav= true,
            showSignupNav= true,
            showAccountLoginNav = true,
            showSingleSearchbar = true,
            hideHeaderDropDownFeatures = false
        } = headerProps;
        const {
            eventPage = EP_HOMEPAGE
        } = headerSingleSearchOptions
        const isPricingMenuShowNew = whiteLabelConfig?.header ? false : isPricingMenuShow
        powerSearchType = powerSearchType === "" ? "company" : powerSearchType;
        const eventType = getOnloadEventType(powerSearchType);

        return (
            <div>
                <MediaQuery maxWidth={991}>
                    <Modal id="homepage_menu_modal" isOpen={this.props.isMenuModalOpen} backdropClassName="homepage-menu-backdrop" fade={false} backdrop="static">
                        {showMenuToggleTabMob && <ModalBody>
                            <div className="menu-modal-header">
                                <>
                                    {whiteLabelConfig?.header && whiteLabelConfig?.header.desktopimagepath ? <picture>
                                        <source className="logo-img" srcSet={whiteLabelConfig.header.desktopimagepath.sourceset} />
                                        <img className="logo-img" src={whiteLabelConfig.header.desktopimagepath.image}  alt="pomanda" />
                                    </picture> :
                                        <picture>
                                            <source className="logo-img" srcSet={`/assets/webpImages/${desktopWebpLogo}`} />
                                            <img className="logo-img" src={`/assets/images/${desktopLogo}`} alt="pomanda" />
                                        </picture>}
                                </>
                                <div className='ml-auto d-flex w-100 justify-content-end'>
                                    {showSingleSearchbar && <div className={`header-searchbar-section ml-2`}>
                                        <SingleSearchBar
                                            formId="single_search_header_section"
                                            searchButtonPlaceholder="Search for a Company or Director..."
                                            searchTextboxValue={headerSearchText}
                                            inputId="header_single_search_input"
                                            eventCreatedKeyDown={HEADER_SINGLE_SEARCH_SEARCHBAR_ENTER}
                                            eventTypeKeyDown={eventType}
                                            eventPage={eventPage}
                                            eventTypeSearch={ET_HEADER_SINGLE_SEARCH}
                                            eventCreatedSearch={HEADER_SINGLE_SEARCH_SEARCHBAR_SEARCH}
                                            eventCreatedOptionClick={HEADER_SINGLE_SEARCH_SEARCHBAR_OPTION}
                                            eventCreatedIconClick={HEADER_SINGLE_SEARCH_SEARCHBAR_ICON}
                                            type="headerSearchbar"
                                            singleSearchTypes={[]}
                                            disableInputForMobile={this.props?.screen?.width < 576 ? true : false}
                                            disableSearchIconClick={this.props?.screen?.width < 576 ? true : false}
                                        />
                                    </div>}
                                    {showSignupNav && !showAccMenu ? 
                                        <div className="header-com-menu header-com-yellow-button ml-2 header-com-menu-signup d-flex align-items-center" onClick={this.toggleLoginModal} pomandae2e="sign_in">
                                            <span>Sign In</span>
                                        </div> 
                                    : null}
                                    <span className="header-menu-close-btn cursor-pointer ml-2" pomandae2e="m_closebutton" onClick={() => this.props.dispatchToggleMenuModal(!this.props.isMenuModalOpen)}>x</span>
                                </div>
                            </div>
                            <div className="menu-modal-nav-section">
                                {menuDropDowns.map((dropDown) => {
                                    const showContent = this.handleDropDownDisplay(dropDown, showAccMenu);
                                    const customUrl = this.getCustomUrl(dropDown?.id, dropDown.url || "");
                                    return showContent ? <div key={`${dropDown.name.replaceAll(" ", "_")}`} className='menu-dropdown-container'>
                                        <Link to={customUrl} className='mb-0 menu-dropdown-header common-link-btn cursor-pointer' onClick={() => this.handleSetActiveDropDown(dropDown.isDropDown, dropDown.id, dropDown.eventType)}>{dropDown.name} <i className={`fa fa-${dropDown.isDropDown ? `chevron-down ${this.state?.activeDropDown === dropDown.id ? "active" : ""}` : "long-arrow-right"} ml-2 pom-font-12`} aria-hidden="true"></i></Link>
                                        {dropDown.isDropDown && this.state?.activeDropDown === dropDown.id ? <div className='menu-dropdown-options-container'>
                                            {dropDown.options.map((option) => {
                                                return option.subOptions ? <div key={`${option.mainOption.replaceAll(" ", "_")}`} className='menu-dropdown-suboptions'>
                                                    <Link to={option.url} onClick={this.closeModalAndInactiveDropDown} className='menu-dropdown-subheader mb-2'>{option.mainOption} <i className={`fa fa-long-arrow-right ml-2 pom-font-14 float-right`} aria-hidden="true"></i></Link>
                                                    <div className='option-container'>
                                                        {option.subOptions.map((subOption) => {
                                                            return <Link key={`${subOption.text.replaceAll(" ","_")}`} onClick={this.closeModalAndInactiveDropDown} className="menu-dropdown-option" to={subOption.url}>{subOption.text}</Link>
                                                        })}
                                                    </div>
                                                </div> : <Link key={`${option.text.replaceAll(" ", "_")}`} onClick={this.closeModalAndInactiveDropDown} className="menu-dropdown-option" to={option.url}>{option.text}</Link>
                                            })}
                                        </div> : null}
                                    </div> : null
                                })}
                            </div>
                            <div className='book-demo-btn-wrapper'>
                                <button className="book-demo-btn cursor-pointer" onClick={this.toggleDemoModal}>Book a Demo</button>
                            </div>
                        </ModalBody>}
                    </Modal>
                </MediaQuery>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    isMenuModalOpen: state.menu.isMenuModalOpen,
    token: state.loginSignup.token,
    powerSearchType: state.searchReducer.powerSearchType,
    headerSingleSearchOptions: state.common.headerSingleSearchOptions,
    headerProps: state.common.headerProps,
    whiteLabelConfig: state.common.whiteLabelConfig,
    headerSearchText: state.common.headerSearchText,
    userInfo: state.userProfile.userInfo,
    screen: state.common.screen
})

const mapDispatchToProps = (dispatch) => ({
    dispatchToggleMenuModal: toggleStatus => dispatch(toggleMenuModal(toggleStatus)),
    dispatchToggleLoginModal: (toggleStatus, trackingInfo, onSuccessRender) => dispatch(toggleLoginModal(toggleStatus, trackingInfo, onSuccessRender)),
    setActivityTrackingData: trackingObj => dispatch(setActivityTrackingData(trackingObj)),
    dispatchSendActivityTracking: (trackingObj) => dispatch(sendActivityTracking(trackingObj)),
    dispatchToggleSignupModal: (toggleStatus, trackingInfo, onSuccessRender) => dispatch(toggleSignupModal(toggleStatus, trackingInfo, onSuccessRender)),
    dispatchSetTryPowerSearch: (status, tryPowerSearchData) => dispatch(setTryPowerSearch(status, tryPowerSearchData)),
    dispatchSetRequestDemoModalStatus: (status) => dispatch(setRequestDemoModalStatus(status)),   
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomepageMenuModal));